<template>
	<a-popover v-model="commentsPopover.show" trigger="click" placement="topLeft">
		<a-row slot="title" class="comments-title">
			<a-col :span="16"> {{ $t('table.loanReview.comments') }} </a-col>
			<a-col :span="8" style="text-align: right">
				<a-icon @click="commentsPopover.show = false" type="close" />
			</a-col>
		</a-row>
		<div slot="content" class="comments-content">
			<div class="comments-list">
				<a-row v-for="item in commentsPopover.commentList" :key="item.id">
					<a-col :span="12" class="name">{{ item.username }}</a-col>
					<a-col :span="12" class="time">{{ item.commentTime }}</a-col>
					<a-col :span="24" class="content">{{ item.comment }}</a-col>
				</a-row>
			</div>
			<a-row>
				<a-input type="textarea" v-model="commentsPopover.content" :rows="4" :maxLength="500" />
			</a-row>
			<a-row style="text-align: right">
				<a-button type="primary" class="send-btn" @click="sendContent">Send</a-button>
			</a-row>
		</div>
		<a-button>
			<img class="icon-btn" src="@/assets/comment.png" />{{ $t('table.loanReview.comments') }}({{ commentsPopover.commentList.length }})
		</a-button>
	</a-popover>
</template>
<script>
import { apiCommentList, apiCommentAdd } from '@/api/check'
export default {
	data() {
		return {
			commentsPopover: {
				show: false,
				content: '',
				commentList: []
			}
		}
	},
	methods: {
		initCommentList() {
			apiCommentList(this.$route.query.id).then((res) => {
				this.commentsPopover.commentList = res
			})
		},
		sendContent() {
			if (this.$Util.isEmpty(this.commentsPopover.content)) {
				this.$message.error("Comments can't null")
				return false
			}
			apiCommentAdd({
				loanId: this.$route.query.id,
				content: this.commentsPopover.content
			}).then(() => {
				this.commentsPopover.content = ''
				this.commentsPopover.show = false
				this.initCommentList()
			})
		}
	},
	created() {
		this.$nextTick(() => {
			this.initCommentList()
		})
	}
}
</script>
