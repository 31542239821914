<template>
	<div style="max-height: 340px; overflow: auto">
		<a-descriptions bordered size="default" v-if="loanPreCreditResponse && riskBasedFinancing">
			<template slot="title">Risk Based Pricing</template>
			<a-descriptions-item label="Risk Rating" :span="3">
				<p style="text-align: center; text-transform: capitalize">
					<a-statistic :value="(riskBasedFinancing.riskRating * 100).toFixed(1)" suffix="%" />
				</p>
			</a-descriptions-item>
			<a-descriptions-item label="Interest Rate" :span="3">
				<p style="text-align: center; text-transform: capitalize">
					<a-statistic :value="(riskBasedFinancing.interestRating * 100).toFixed(1)" suffix="%" />
				</p>
			</a-descriptions-item>
			<a-descriptions-item label="Duration of Loan" :span="3">
				<p style="text-align: center; text-transform: capitalize"> {{ riskBasedFinancing.durationOfLoan }} </p>
			</a-descriptions-item>
			<a-descriptions-item label="% Financing" :span="3">
				<p style="text-align: center; text-transform: capitalize">
					<a-statistic :value="(riskBasedFinancing.percentageOfFinance * 100).toFixed(0)" suffix="%" />
				</p>
			</a-descriptions-item>
			<a-descriptions-item label="Financing Amount" :span="3">
				<p style="text-align: center; text-transform: capitalize; font-weight: bold"
					><a-statistic :precision="0" :value="riskBasedFinancing.financeAmount" suffix="Ksh" />
				</p>
			</a-descriptions-item>
			<a-descriptions-item label="Risk Alert" :span="3">
				<a-popover v-model="riskPopoverShow" trigger="click" placement="right">
					<a-row slot="title" class="comments-title">
						<a-col :span="16"> Risk Warning Details </a-col>
						<a-col :span="8" style="text-align: right">
							<a-icon @click="riskPopoverShow = false" type="close" />
						</a-col>
					</a-row>
					<a-table
						:scroll="{ x: 400, y: 400 }"
						slot="content"
						:columns="columns"
						:pagination="false"
						:data-source="loanPreCreditResponse.creditRules"
					/>
					<p style="text-align: center">
						<a-button type="link">{{ riskBasedFinancing.riskAlert }}</a-button>
					</p>
				</a-popover>
			</a-descriptions-item>
		</a-descriptions>
		<a-collapse default-active-key="1" :bordered="false">
			<a-collapse-panel :showArrow="false" key="1" :header="$t('table.loanReview.reviewProcess')">
				<a-icon slot="extra" type="down" />
				<a-timeline>
					<a-timeline-item v-for="item in checkRecords" :key="item.id">
						<a-icon
							slot="dot"
							v-if="item.pass === 1 || item.nodeAction == 'disburse_info_submit'"
							type="check-circle"
							style="font-size: 24px; color: #52c41a"
						/>
						<a-icon slot="dot" v-else type="close-circle" style="font-size: 24px; color: #ff4d4f" />
						<a-row style="padding-left: 10px">
							<a-col :span="12" class="name">{{ item.checkerName }}</a-col>
							<a-col :span="12" class="time">{{ item.checkTime }}</a-col>
							<a-col
								:span="24"
								class="status"
								:style="{
									color:
										(item.pass === 0 && item.nodeAction !== 'disburse_info_submit') || item.nodeAction === 'apply_info_client_appeal' ? '#ff4d4f' : ''
								}"
							>
								{{ $t(`table.applyInfo.recordCloums.status.${item.nodeAction}`) }}
							</a-col>
							<a-col v-if="item.recommendAmount || item.checkText" :span="24" class="content">
								<p style="text-transform: capitalize" v-if="item.recommendAmount">
									<span>{{ $t('table.loanReview.recommendLoanAmount') }}:</span>
									{{ item.recommendAmountType + ' ' + item.recommendAmount }} Ksh (Estimated
									{{ item.recommendAmountType === 'net' ? 'Gross' : 'Net' }} Amount ：{{ item.calAmount }} KSh)
								</p>
								<p v-if="item.checkText">
									<span>{{ $t('table.loanReview.comments') }}:</span>{{ item.checkText }}
								</p>
							</a-col>
						</a-row>
					</a-timeline-item>
				</a-timeline>
			</a-collapse-panel>
		</a-collapse>
	</div>
</template>
<script>
export default {
	props: ['loanPreCreditResponse', 'checkRecords', 'riskBasedFinancing'],
	data() {
		return {
			riskPopoverShow: false,
			calAmount: []
		}
	},
	computed: {
		columns() {
			return [
				{
					title: 'No.',
					dataIndex: 'creditCode',
					width: 150,
					align: 'center',
					customRender: (text, row, index) => {
						return index + 1
					}
				},
				{
					title: 'Warning',
					dataIndex: 'creditDesc',
					align: 'center'
				}
			]
		}
	}
}
</script>
