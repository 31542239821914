<template>
	<div style="display: inline-block">
		<a-button @click="briefModalShow = true"><img class="icon-btn" src="@/assets/Record.png" />{{ $t('operate.loanBrief') }} </a-button>
		<a-modal v-drag-modal :title="$t('operate.loanBrief')" :dialog-style="{ top: '60px' }" v-model="briefModalShow" width="928px">
			<a-table bordered :data-source="checkListData" :pagination="false" :columns="checkColumns" class="tablecheck">
				<template slot="checkList" slot-scope="text"> {{ $t(`loanBrief.${text}`) }} </template>
				<template slot="check" slot-scope="text, record">
					<a-input
						v-if="fillData.includes(record.checkList)"
						style="margin: -5px 0"
						v-model="loanBriefData[record.modelValue ? record.modelValue : record.checkList]"
						:maxLength="100"
						placeholder="Please fill out"
					/>
					<p v-else>{{ text }}</p>
				</template>
				<!-- <template slot="check" slot-scope="text"> {{ text }} </template> -->
			</a-table>
			<!-- <a-table bordered :data-source="checkListFillData" :pagination="false" :columns="checkColumns" :showHeader="false">
				<template slot="checkList" slot-scope="text"> {{ $t(`loanBrief.${text}`) }} </template>
				<a-input
					slot="check"
					slot-scope="text, record, index"
					style="margin: -5px 0"
					v-model="loanBriefData[checkListFillData[index].checkList]"
					:maxLength="100"
					:disabled="checkListFillData[index].checkList === 'loanOffer' || checkListFillData[index].checkList === 'installments'"
					placeholder="Please fill out"
				/>
			</a-table> -->
			<template slot="footer">
				<a-button key="submit" type="primary" @click="saveLoanBrief"> Submit </a-button>
			</template>
		</a-modal>
	</div>
</template>
<script>
import { apiFindLoanBrief, apiSaveLoanBrief } from '@/api/check'
export default {
	props: ['loanBriefListData'],
	data() {
		return {
			briefModalShow: false,
			loanBriefData: {},
			checkListData: [],
			checkListFillData: [],
			fillData: [
				'customerBusiness',
				'averageIncomeMpesa',
				'averageIncomeBank',
				'averageDailyBalance',
				'creditPolicyCarRange',
				'creditPolicyMonthlyIncomeMpesa',
				'creditPolicyMaximumAverageBalanceDaily',
				'creditPolicyBankStatementsIncomeBalance',
				'characterNote',
				'capitalContribution',
				'collateral',
				'repaymentCapacityOfTheClient',
				'conditionOfCollateralProvided'
			],
			checkColumns: [
				{
					title: 'CheckList',
					width: 500,
					dataIndex: 'checkList',
					scopedSlots: {
						customRender: 'checkList'
					}
				},
				{
					title: 'Check',
					dataIndex: 'check',
					width: 300,
					scopedSlots: {
						customRender: 'check'
					}
				}
			]
		}
	},
	methods: {
		initFindLoanBrief() {
			apiFindLoanBrief(this.$route.query.id).then((res) => {
				if (res === null) {
					this.loanBriefData = {
						loanId: this.$route.query.id,
						loanOffer: '',
						sourceOfIncome: '',
						installments: '',
						averageIncomeMpesa: '',
						averageIncomeBank: '',
						averageDailyBalance: '',
						creditPolicyCarRange: '',
						creditPolicyMonthlyIncomeMpesa: '',
						creditPolicyMaximumAverageBalanceDaily: '',
						creditPolicyBankStatementsIncomeBalance: '',
						characterNote: '',
						capitalContribution: '',
						collateral: '',
						repaymentCapacityOfTheClient: '',
						conditionOfCollateralProvided: '',
						...this.loanBriefListData
					}
				} else {
					this.loanBriefData = { ...res, ...this.loanBriefListData }
				}
				this.checkListData = [
					{
						checkList: 'customerName',
						check: this.loanBriefData.basicFullName
					},
					{
						checkList: 'customerTelNo',
						check: this.loanBriefData.mobiles.map((item) => item.mobile).join('/')
					},
					{
						checkList: 'salesAgent',
						check: this.loanBriefData.salesmanName
					},
					{
						checkList: 'customerBusiness',
						check: this.loanBriefData.sourceOfIncome,
						modelValue: 'sourceOfIncome'
					},
					{
						checkList: 'loanPurpose',
						check: this.loanBriefData.loanUse
					},
					{
						checkList: 'forcedSale',
						check:
							this.loanBriefData.loanCarInfos.length > 1
								? this.loanBriefData.loanCarInfos.map((item) => item.valuation.registrationNumber + ' - ' + item.valuation.saleValuation).join('/')
								: this.loanBriefData.loanCarInfos[0].valuation.saleValuation
					},
					{
						checkList: 'loanRequest',
						check: this.loanBriefData.applyAmount
					},
					{
						checkList: 'loanOffer',
						check: this.loanBriefData.loanOffer
					},
					{
						checkList: 'installments',
						check: this.loanBriefData.installments
					},
					{
						checkList: 'averageIncomeMpesa',
						check: this.loanBriefData.averageIncomeMpesa
					},
					{
						checkList: 'averageIncomeBank',
						check: this.loanBriefData.averageIncomeBank
					},
					{
						checkList: 'averageDailyBalance',
						check: this.loanBriefData.averageDailyBalance
					},
					{
						checkList: 'creditPolicyCarRange',
						check: this.loanBriefData.creditPolicyCarRange
					},
					{
						checkList: 'creditPolicyMonthlyIncomeMpesa',
						check: this.loanBriefData.creditPolicyMonthlyIncomeMpesa
					},
					{
						checkList: 'creditPolicyMaximumAverageBalanceDaily',
						check: this.loanBriefData.creditPolicyMaximumAverageBalanceDaily
					},
					{
						checkList: 'creditPolicyBankStatementsIncomeBalance',
						check: this.loanBriefData.creditPolicyBankStatementsIncomeBalance
					},
					{
						checkList: 'characterNote',
						check: this.loanBriefData.characterNote
					},
					{
						checkList: 'capitalContribution',
						check: this.loanBriefData.capitalContribution
					},
					{
						checkList: 'collateral',
						check: this.loanBriefData.collateral
					},
					{
						checkList: 'repaymentCapacityOfTheClient',
						check: this.loanBriefData.repaymentCapacityOfTheClient
					},
					{
						checkList: 'conditionOfCollateralProvided',
						check: this.loanBriefData.conditionOfCollateralProvided
					}
				]
			})
		},
		saveLoanBrief() {
			apiSaveLoanBrief(this.loanBriefData).then(() => {
				this.briefModalShow = false
			})
		}
	},
	created() {
		this.$nextTick(() => {
			this.initFindLoanBrief()
			// this.checkListData = [
			// 	{
			// 		checkList: 'customerName',
			// 		check: this.loanBriefListData.basicFullName
			// 	},
			// 	{
			// 		checkList: 'customerTelNo',
			// 		check: this.loanBriefListData.mobiles.map((item) => item.mobile).join('/')
			// 	},
			// 	{
			// 		checkList: 'salesAgent',
			// 		check: this.loanBriefListData.salesmanName
			// 	},
			// 	{
			// 		checkList: 'customerBusiness',
			// 		check:
			// 			this.$t(`table.applyInfo.personalLabel.sourceMap.${this.loanBriefListData.incomeSource.sourceType}`) +
			// 			' ' +
			// 			this.loanBriefListData.incomeSource.remarks
			// 	},
			// 	{
			// 		checkList: 'loanPurpose',
			// 		check: this.loanBriefListData.loanUse
			// 	},
			// 	{
			// 		checkList: 'forcedSale',
			// 		check:
			// 			this.loanBriefListData.loanCarInfos.length > 1
			// 				? this.loanBriefListData.loanCarInfos.map((item) => item.valuation.registrationNumber + ' - ' + item.valuation.saleValuation).join('/')
			// 				: this.loanBriefListData.loanCarInfos[0].valuation.saleValuation
			// 	},
			// 	{
			// 		checkList: 'loanRequest',
			// 		check: this.loanBriefListData.applyAmount
			// 	}
			// ]
			// this.checkListFillData = [
			// 	{
			// 		checkList: 'loanOffer',
			// 		check: ''
			// 	},
			// 	{
			// 		checkList: 'installments',
			// 		check: ''
			// 	},
			// 	{
			// 		checkList: 'averageIncomeMpesa',
			// 		check: ''
			// 	},
			// 	{
			// 		checkList: 'averageIncomeBank',
			// 		check: ''
			// 	},
			// 	{
			// 		checkList: 'averageDailyBalance',
			// 		check: ''
			// 	},
			// 	{
			// 		checkList: 'creditPolicyCarRange',
			// 		check: ''
			// 	},
			// 	{
			// 		checkList: 'creditPolicyMonthlyIncomeMpesa',
			// 		check: ''
			// 	},
			// 	{
			// 		checkList: 'creditPolicyMaximumAverageBalanceDaily',
			// 		check: ''
			// 	},
			// 	{
			// 		checkList: 'creditPolicyBankStatementsIncomeBalance',
			// 		check: ''
			// 	},
			// 	{
			// 		checkList: 'characterNote',
			// 		check: ''
			// 	},
			// 	{
			// 		checkList: 'capitalContribution',
			// 		check: ''
			// 	},
			// 	{
			// 		checkList: 'collateral',
			// 		check: ''
			// 	},
			// 	{
			// 		checkList: 'repaymentCapacityOfTheClient',
			// 		check: ''
			// 	},
			// 	{
			// 		checkList: 'conditionOfCollateralProvided',
			// 		check: ''
			// 	}
			// ]
		})
	}
}
</script>
